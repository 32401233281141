import BlankSelector from "yinzhilv-js-sdk/frontend/common-project/component/haolv/blank-selector/1.0.0/index.vue";

// 已绑定银行卡
import bankAccount_getBankAccountList from "@/lib/data-service/haolv-default/consumer_web_bankAccount_getBankAccountList.js";
// 删除绑定银行卡
import bankAccount_delBankAccount from "@/lib/data-service/haolv-default/consumer_web_bankAccount_delBankAccount.js";
// 发起提款申请
import wallet_withdrawApply from "@/lib/data-service/haolv-default/consumer_wallet_withdrawApply.js";

export default {
  components: {
    BlankSelector,
  },
  data() {
    return {
      loading: false,
      form: {
        // 金额
        dealAmount: "",
        // 收款人
        payeeName: "",
        // 卡号
        bankCard: "",
        // 银行名称
        bankName: "",
        // 银行id
        bankId: "",
        // 开户行
        openBank: "",
        // 是否保存银行卡
        isSave: false,
        // 支付密码
        password: "",
      },
      bankNameObj: {
        id: "",
        bankCode: "",
        bankLogo: "",
        bankName: "",
      },
      rules: {
        payeeName: [
          { required: true, message: "请输入收款人", trigger: "blur" },
        ],
        bankCard: [
          { required: true, message: "请输入银行卡号", trigger: "blur" },
        ],
        bankName: [
          { required: true, message: "请输入银行名称", trigger: "blur" },
        ],
        openBank: [
          { required: true, message: "请输入开户行", trigger: "blur" },
        ],
      },
      bankCardList: [],
      id: "",

      dialogVisible: false,
    };
  },
  computed: {
    // 当绑定银行卡大于等于三条时，或选择已绑定银行卡是，不可勾选保存
    disabledIsSave() {
      if (this.bankCardList.length >= 3 || this.id) return true;
    },
  },
  methods: {
    // 请求已绑定银行卡
    req_account_list() {
      this.loading = true;
      bankAccount_getBankAccountList()
        .then((res) => {
          this.bankCardList = res.datas;
          // const _this = this;
          // this.bankCardList.forEach((item) => {
          //   if (item.isDefault === 1) {
          //     _this.select_account(item);
          //   }
          // })
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    init() {
      // 获取提款金额
      this.form.dealAmount = this.$route.query.dealAmount;
      this.req_account_list();
    },
    reset() {
      this.close_dialog();
      this.id = "";
      this.bankNameObj = null;
      this.form = {
        // 金额
        dealAmount: "",
        // 收款人
        payeeName: "",
        // 卡号
        bankCard: "",
        // 银行名称
        bankName: "",
        // 银行id
        bankId: "",
        // 开户行
        openBank: "",
        // 是否保存银行卡
        isSave: false,
        // 支付密码
        password: "",
      };
    },

    // 手动修改时清除银行卡识别id
    change_account() {
      this.id = "";
    },

    // 删除已绑定
    delet_account(id, index) {
      this.$confirm("是否确定删除此卡号?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.loading = true;
          bankAccount_delBankAccount({ id })
            .then((res) => {
              this.bankCardList.splice(index, 1);
              this.loading = false;
              this.$message.success("删除成功");
            })
            .catch((err) => {
              this.loading = false;
            });
        })
        .catch(() => {});
    },
    // 选择已绑定银行卡
    select_account(item) {
      const {
        id,
        bankUserName,
        bankAccount,
        openingBankBranch,

        openingBank,
      } = item;

      this.bankNameObj = {
        id: openingBank,
      };

      this.id = id;
      const form = this.form;
      form.payeeName = bankUserName;
      form.bankCard = bankAccount;
      form.openBank = openingBankBranch;
    },

    close_dialog() {
      this.dialogVisible = false;
      this.form.password = '';
      this.$refs.passwordForm.clearValidate();
    },

    // 提交提款申请
    commit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.dialogVisible = true;
        } else {
          return false;
        }
      });
    },

    commit_password() {
      this.$refs.passwordForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          wallet_withdrawApply(this.form)
            .then((res) => {
              this.loading = false;
              this.$message.success("提款成功");
              this.reset();
              this.$router.replace({
                name: "admin-finance-balance-list",
              });
            })
            .catch((err) => {
              this.$message.error("支付密码错误，请重试");
              this.loading = false;
            });
        } else {
        }
      });
    },
  },
  created() {},
  activated() {
    this.init();
  },
  deactivated () {
    this.reset();
  },
  watch: {
    bankNameObj(val) {
      const form = this.form;
      if (val) {
        const { bankName, bankCode } = val;
        form.bankName = bankName;
        form.bankId = bankCode;
      } else {
        form.bankName = "";
        form.bankId = "";
      }
    },
  },
};
