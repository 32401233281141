/*
*发起提款申请  http://219.128.52.2:18765/doc.html#/haolv-consumer/t-us-company-account-controller/withdrawApplyUsingPOST
*/
const __request = require(`./__request/__request_contentType_json`)
const request = (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix: '/consumer/wallet/withdrawApply',
        data: data,
        enable_error_alert: false,
    }
    return __request(pParameter)
}
export default request